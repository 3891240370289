import "pickadate/lib/picker";
import "pickadate/lib/picker.date";

import "jquery-mask-plugin";

window.disabledDayparts;

const updateDaypartOptions = debounce(200, _updateDaypartOptions)
const getDisabledDays = debounce(200, () => _getDisabledDays().then(updateDaypartOptions));
const fetchDefaultLineItems = debounce(200, () =>
  _fetchDefaultLineItems().then(lis => {
    if (window.editing) {
      $('#details-changed-warning').show()
      let list = $('ul#default-reference')
      list.html('')
      lis.forEach((li, i) => list.append(renderStaticLineItem(li)));
      let subtotal = lis.reduce((acc, x) => x.amount + acc, 0)
      list.append(renderSubtotalLine(subtotal))
    } else {
      insertDefaultLineItems(lis)
    }
  }))


$(document).ready(function() {
  window.customLineItems = window.customLineItems || []
  window.originalCustomLineItems = window.originalCustomLineItems || []
  if (!window.customLineItems.length) {
    window.customLineItems = deepCopy(window.originalCustomLineItems)
  }

  $("#id_phone_number").mask("(000) 000-0000");
  !window.isAdminEditPage && $("#id_state").val("NC");

  setupDatePickers()

  getDisabledDays();
  $("#id_boat").on("change", getDisabledDays)

  window.isAdminPage && !window.rentalIsExpired && insertButtons()

  if (window.rentalIsExpired) {
    $('form input, form select').each((i, el) => el.disabled = true)
  }

  // Refetch default line items when rental details change
  let noSubtotal = $("form.rental").hasClass("no-subtotal");
  if (!noSubtotal) {
    !window.defaultLineItems && fetchDefaultLineItems()
    $("form.rental").on("change", e => {
      if (["id_start_date", "id_boat", "id_end_date", "id_daypart", "id_include_insurance", "id_include_cancellation_coverage"].includes(e.target.id)) {
        fetchDefaultLineItems()
      }
    })
  }

  // Update tax and cc fees when editable line items change
  $('form.rental').on('blur', '.dollar-input', e => {
    let amount = parseDollarAmount(e.target.value)
    e.target.value = formatDollarAmount(amount)
    let index = e.target.closest('li').dataset['i']
    window.customLineItems[index]['amount'] = amount
    updateTaxFeesAndTotal()
  })

  $('form.rental').on('change', '#editable-line-items-container input[name*=name]', function(e) {
    let index = e.target.closest('li').dataset['i']
    window.customLineItems[index]['name'] = e.target.value
  })

  $('form.rental').on('change', '#editable-line-items-container input[name*=description]', function(e) {
    let index = e.target.closest('li').dataset['i']
    window.customLineItems[index]['description'] = e.target.value
  })

  $('#formset-management-form').before(`
    <div id="details-changed-warning" class="alert alert-warning" style="display: none;">
      <div style="display: flex; justify-content: flex-end;">
        <button class="close" type="button" onclick="$('#details-changed-warning').hide()">&times;</button>
      </div>
      The rental details have changed since you began editing custom line items.
      You may want to click "Use Default Line Items" to pull in those changes. For reference,
      here is what the <b>DEFAULT</b> line items would look like for this order:
      <ul id="default-reference"></ul>
    </div>
  `)

  if (window.lineItemErrors && window.lineItemErrors.length) {
    $('#details-changed-warning').before(`
      <div class="alert alert-danger">
        ${JSON.stringify(window.lineItemErrors)}
      </div>
    `)}

  if (window.customLineItems.length) {
    insertCustomLineItems(window.customLineItems);
    window.editing = true;
    $('#revert-custom-changes, #edit-line-items').hide()
    $('form.rental .dollar-input').on('change', _ => $('#revert-custom-changes').show())
  } else {
    window.defaultLineItems && insertDefaultLineItems(window.defaultLineItems)
  }
});


function isLineItemDeleted(li) {
  return $(li).find('input[name~="DELETE"]').val()
}

function updateTaxFeesAndTotal() {
  let subtotal = $('.dollar-input').toArray().reduce(
    (acc, el) => acc + (isLineItemDeleted(el.closest('li')) ? 0 : parseDollarAmount(el.value)),
    0)
  let container = $('#editable-line-items-container')
  container.find('.subtotal-line .checkout-subtotal').text(formatDollarAmount(subtotal))
  container.find('.tax').remove()
  container.find('.card-fee').remove()
  container.find('.total-line').remove()
  container.append(renderTaxAndCcFee(subtotal))
  container.append(renderTotalLine(totalCost(subtotal)))
}

function addLineItem(li) {
  window.customLineItems.push(li)
  insertCustomLineItems(window.customLineItems)
}

function _fetchDefaultLineItems() {
  return fetch("/rentals/subtotal/?" + $("form.rental").serialize())
    .then(res => res.json())
    .then(data => {
      window.defaultLineItems = data.line_items
      return data.line_items
    });
}

function insertDefaultLineItems(lineItems) {
  insertCustomLineItems(window.customLineItems)
  $('#editable-line-items-container').hide()
  let lineItemContainer = $('<div id="line-items-container"><ul style="padding-left: 0;"></ul></div>');
  let list = lineItemContainer.find("ul");
  lineItems.forEach((li, i) => list.append(renderStaticLineItem(li)));
  let subtotal = lineItems.reduce((acc, x) => x.amount + acc, 0)
  list.append(renderSubtotalLine(subtotal))
  list.append(renderTaxAndCcFee(subtotal))
  list.append(renderTotalLine(totalCost(subtotal)))

  lineItemContainer.insertBefore("form.rental .form-buttons");
}


function insertCustomLineItems(lineItems) {
  $('form.rental #line-items-container').remove()
  $('form.rental #editable-line-items-container').remove()
  let lineItemContainer = $('<div id="editable-line-items-container"><ul style="padding-left: 0;"></ul></div>')
  let list = lineItemContainer.find('ul')
  lineItems.forEach((li, i) => list.append(renderEditableLineItem(li, i)))
  let subtotal = lineItems.filter(x => !x.deleted).reduce((acc, x) => acc + x.amount, 0)
  list.append(renderSubtotalLine(subtotal))
  list.append(`
    <div style="text-align: center; margin-top: .5rem;">
      <button type="button" class="add-line-item btn btn-secondary">
        Add Line Item
      </button>
    </div>`)
  list.append(renderTaxAndCcFee(subtotal))
  list.append(renderTotalLine(totalCost(subtotal)))
  lineItemContainer.insertBefore("form.rental .form-buttons");


  $('form.rental button.add-line-item').on(
    'click',
    _ => addLineItem({name: "", description: "", amount: 0}))
  lineItemContainer.on('click', '.remove-li', function() {
    let index = $(this).closest('li').data('i')
    deleteLineItem(index)
    insertCustomLineItems(window.customLineItems)
  })

  $('#id_line_items-TOTAL_FORMS').val(lineItems.length)
}

function deleteAllLineItems() {
  for (let i = window.customLineItems.length - 1; i >= 0; i--) {
    deleteLineItem(i)
  }
}

function insertButtons() {
  let buttonsDiv = $('form.rental .form-buttons')
  let useDefaultButton = $('<button id="use-default-button" type="button" class="btn btn-secondary">Use Default Line Items</button>')
  let editButton = $('<button id="edit-line-items" type="button" class="btn btn-secondary">Edit Default Line Items</button>')
  let revertCustomButton = $('<button id="revert-custom-changes" type="button" class="btn btn-secondary">Undo Changes to Custom Line Items</button>')

  editButton.on('click', () => {deleteAllLineItems()
                                window.customLineItems.push(...deepCopy(window.defaultLineItems))
                                insertCustomLineItems(window.customLineItems);
                                window.editing = true;
                                editButton.hide()
                                useDefaultButton.show()
                                revertCustomButton.show()})
  useDefaultButton.on('click', () => {deleteAllLineItems()
                                      insertDefaultLineItems(window.defaultLineItems);
                                      window.editing = false;
                                      $('#details-changed-warning').hide()
                                      useDefaultButton.hide()
                                      revertCustomButton.show()
                                      editButton.show()})
  revertCustomButton.on('click', function() {window.editing = true;
                                             revertCustomButton.hide()
                                             useDefaultButton.show()
                                             editButton.hide()
                                             window.customLineItems = deepCopy(window.originalCustomLineItems)
                                             insertCustomLineItems(window.customLineItems)})

  buttonsDiv.prepend(useDefaultButton, editButton)
  if (window.originalCustomLineItems.length) {
    buttonsDiv.prepend(revertCustomButton)
  } else {
    useDefaultButton.hide()
  }
}


function calcTax(preTaxTotal) { return Math.ceil(preTaxTotal * .03) }
function calcCardFee(preFeeTotal) { return Math.ceil((30+preFeeTotal)/(1-.03)) - preFeeTotal }

function totalCost(subtotal) {
  let tax = calcTax(subtotal)
  let cardFee = calcCardFee(subtotal + tax)
  return subtotal + tax + cardFee
}

function formatDollarAmount(amt) { return "$" + (amt / 100).toFixed(2) }
function parseDollarAmount(str) { return parseInt(parseFloat(str.replace('$', '')) * 100) }

function renderTaxAndCcFee(subtotal) {
  let tax = calcTax(subtotal)
  let cardFee = calcCardFee(subtotal + tax)
  return (
    renderStaticLineItem({id: 'tax',
                          name: "Tax",
                          description: "3% tax",
                          amount: tax})
    +
    renderStaticLineItem({id: 'card-fee',
                          name: "Credit Card Fee",
                          description: "Covers the fee we pay to process credit card transactions",
                          amount: cardFee})
  )
}

function renderSubtotalLine(subtotal) {
  return `
    <li class="subtotal-line py-1" style="font-size: 1.2em; display: flex; justify-content: space-between; align-items: center;">
      <div style="margin-right: auto;">Subtotal</div>
      <div style="width: 8rem; text-align: right;" class="checkout-subtotal">${formatDollarAmount(subtotal)}</div>
    </li>`
}

function renderTotalLine(total) {
  return `
    <li class="total-line py-1" style="font-size: 1.2em; display: flex; justify-content: space-between; align-items: center;">
      <div style="margin-right: auto;">Total</div>
      <div style="width: 8rem; text-align: right;" class="checkout-total">${formatDollarAmount(total)}</div>
    </li>`
}

function renderStaticLineItem(li) {
  return `
  <li class="py-1 ${li.id || ''}" style="display: flex; justify-content: space-between; align-items: center; border-bottom: 1px solid black;">
    <div style="margin-right: auto;">
      <div>${li.name}</div>
      <div style="font-size: .8em">${li.description}</div>
    </div>
    <div style="width: 8rem; text-align: right;">${formatDollarAmount(li.amount)}</div>
  </li>`
}

function renderEditableLineItem(li, i) {
  return `
  <li class="line-item py-1" data-i="${i}" style="display: ${li.deleted ? 'none' : 'flex'}; justify-content: space-between; align-items: center; border-bottom: 1px solid black; gap: .5rem;">
    ${!li.id ? '' : `
      <input name="line_items-${i}-id" type="hidden" value="${li.id}" />
      <input name="line_items-${i}-DELETE" type="hidden" value="${li.deleted || ''}" />`}
    <div style="margin-right: auto;">
      <input name="line_items-${i}-name" value="${li.name}" placeholder="Line Item Name" required />
      <input name="line_items-${i}-description"
             value="${li.description || ''}"
             style="margin-top: .5rem; font-size: .8em"
             placeholder="Line Item Description" />
    </div>
    <input name="line_items-${i}-amount"
           value="${formatDollarAmount(li.amount)}"
           inputmode="decimal"
           class="dollar-input" type="text" style="width: 8rem; text-align: right;" />
    <button type="button" class="remove-li btn-sm btn-danger" style="padding:.5rem;">${trashIcon()}</button>
  </li>`
}


function deleteLineItem(i) {
  let lineItem = window.customLineItems[i]
  let li = $(`input[name="line_items-${i}-name"]`).closest('li');
  if (lineItem.id) {
    lineItem.deleted = true
  } else {
    window.customLineItems.splice(i,1)
  }
}

// Update prices on calendar
function fetchPrices() {
  let boatId = $("#id_boat").val()
  return fetch(`/rentals/prices/?boat_id=${boatId}`)
    .then(res => res.json())
    .then(res => {
      if (typeof res === "string") throw new Error("Failed to fetch price data")
      window.boat = res.boat
      window.rentalRates = res.rates.map(rate => ({...rate,
                                                   start_date: new Date(rate.start_date),
                                                   end_date: new Date(rate.end_date)}))
    })
}

function potentialPrices(weekdayType, daypart, available_dayparts, rate) {
  let prices = available_dayparts.map(daypart => {
    let daypartKey = daypart === 'full-day' ? 'daily' : daypart
    return [daypart, rate[`${weekdayType}_${daypartKey}_rate`]]
  })
  return Object.fromEntries(prices)
}

function renderPrices() {
  if (!window.boat) return
  window.rentalRates = window.rentalRates || []
  $(".picker__day").each(function() {
    let date = new Date(this.attributes["aria-label"].nodeValue)
    // Days are 0 indexed starting with Monday
    // Friday counts as a weekend day
    let weekdayType;
    if (date.getDay() == 5)      weekdayType = 'saturday'
    else if (date.getDay() >= 4) weekdayType = 'weekend'
    else                         weekdayType = 'weekday'

    let daypart = $('#id_daypart').val()
    let priceOverride = window.rentalRates.find(rate =>
      rate.start_date <= date && date <= rate.end_date)
    let pricingObj = priceOverride || window.boat;
    let prices = potentialPrices(weekdayType, daypart, window.disabledDayparts.available_dayparts, pricingObj)
    let price = prices[daypart] || Math.min(...Object.values(prices))

    $(this).find(".day-price").remove()
    $(this).append(`<div class="day-price">$${price}</div>`)
  })

  window.boat.is_gas_prepaid
    ? $('#div_id_accept_gas_not_prepaid').hide()
    : $('#div_id_accept_gas_not_prepaid').show()
}

function _updateDaypartOptions() {
  if (!window.disabledDayparts) return
  let startDate = $("#id_start_date")[0].value
  let endDate = $("#id_end_date")[0].value
  $("#id_daypart option").each(function() {
    if (startDate !== endDate || !window.disabledDayparts.available_dayparts) {
      this.disabled = this.value !== 'full-day';
    } else {
      let disabledDaypartsForDay = window.disabledDayparts.days[startDate]
      this.disabled = disabledDaypartsForDay && disabledDaypartsForDay.includes(this.value) || !window.disabledDayparts.available_dayparts.includes(this.value)
    }
  })
  if (!$("#id_daypart").val() && $("#id_daypart option:not([disabled])")[0]) {
    $("#id_daypart").val($("#id_daypart option:not([disabled])")[0].value)
  }
}

function _getDisabledDays() {
  let boat_id = $("#id_boat").val();
  let url = new URL("/rentals/disabled-days/", location.origin)
  url.searchParams.set('boat_id', boat_id)
  window.rental_under_edit && url.searchParams.set('rental_id', window.rental_under_edit)
  return fetch(url)
    .then(response => response.json())
    .then(data => {
      window.disabledDayparts = data
      let days = Object.entries(data.days)
          .filter(([ day, dayparts ]) => data.available_dayparts.every(dp => dayparts.includes(dp)))
          .map(([ day ]) => strToPickadateArr(day))
      let ranges = data.ranges.map(range => ({
        from: strToPickadateArr(range.start_date),
        to: strToPickadateArr(range.end_date)
      }))
      $("#id_start_date")
        .pickadate("picker")
        .set("enable", true);
      $("#id_start_date")
        .pickadate("picker")
        .set("disable", days.concat(ranges));
      $("#id_end_date")
        .pickadate("picker")
        .set("enable", true);
      $("#id_end_date")
        .pickadate("picker")
        .set("disable", data.is_house_boat ? [] : days.concat(ranges));

      if (data.is_house_boat) {
        $("a#boat-details").attr('href', data.boat_details_url)
        $("p#read-boat-details").removeClass("d-none")
        $("div#div_id_start_date label").text("Pick Up (2 p.m.)*")
        $("div#div_id_end_date label").text("Drop Off (12 p.m.)*")
      } else {
        $("p#read-boat-details").addClass("d-none")
        $("div#div_id_start_date label").text("Start Date*")
        $("div#div_id_end_date label").text("End Date*")
      }
    });
}

function strToPickadateArr(str) {
  const d = str.split("-");
  return [parseInt(d[0]), parseInt(d[1]) - 1, parseInt(d[2])];
}

function setupDatePickers() {
  $("#id_start_date, #id_end_date").pickadate({
    format: "yyyy-mm-dd",
    formatSubmit: "yyyy-mm-dd",
    hiddenName: true,
    min: true,
    // TODO set this to the end of this year once
    // the client has updated their boat prices
    // we've temporarily allowed admins so they can test
    max: new Date(new Date().getFullYear(),
                  11,
                  31)
  });

  // end date defaults to start
  let params = new URLSearchParams(window.location.search);
  if (!params.has("end_date") && !window.isAdminEditPage) {
    let startDate = $("#id_start_date").pickadate("picker").get("select")
    $("#id_end_date").pickadate("picker").set("select", startDate);
  }

  // changing start date keeps end date in sync
  $("#id_start_date").on("change", function() {
    let startDate = $("#id_start_date").pickadate("picker").get("select")
    $("#id_end_date").pickadate("picker").set("select", startDate);
    $("#id_end_date").pickadate("picker").set("min", $(this).val());
  });

  $("#id_end_date, #id_start_date").on("change", updateDaypartOptions)

  fetchPrices().catch(() => {})
  $("#id_boat").on("change", () => fetchPrices().then(renderPrices)
                                                .catch(() => {}))
  $("#id_start_date").pickadate("picker").on("render", renderPrices)
  $("#id_end_date").pickadate("picker").on("render", renderPrices)
  $("#id_daypart").on("change", renderPrices)
}

function trashIcon() {
  return `
    <svg style="width: 1.3rem; height:1.3rem; fill: currentcolor;" viewBox="0 0 16 16"><path d="M6.281 6.563v5.156c0 0.094-0.031 0.156-0.063 0.188-0.063 0.063-0.125 0.094-0.219 0.094h-0.563c-0.094 0-0.156-0.031-0.219-0.094-0.063-0.031-0.063-0.094-0.063-0.188v-5.156c0-0.063 0-0.156 0.063-0.188 0.063-0.063 0.125-0.094 0.219-0.094h0.563c0.094 0 0.156 0.031 0.219 0.094 0.031 0.031 0.063 0.125 0.063 0.188zM8.563 6.563v5.156c0 0.094-0.031 0.156-0.063 0.188-0.063 0.063-0.125 0.094-0.219 0.094h-0.563c-0.094 0-0.156-0.031-0.219-0.094-0.031-0.031-0.063-0.094-0.063-0.188v-5.156c0-0.063 0.031-0.156 0.063-0.188 0.063-0.063 0.125-0.094 0.219-0.094h0.563c0.094 0 0.156 0.031 0.219 0.094 0.031 0.031 0.063 0.125 0.063 0.188zM10.844 6.563v5.156c0 0.094 0 0.156-0.063 0.188-0.063 0.063-0.125 0.094-0.219 0.094h-0.563c-0.094 0-0.156-0.031-0.219-0.094-0.031-0.031-0.063-0.094-0.063-0.188v-5.156c0-0.063 0.031-0.156 0.063-0.188 0.063-0.063 0.125-0.094 0.219-0.094h0.563c0.094 0 0.156 0.031 0.219 0.094 0.063 0.031 0.063 0.125 0.063 0.188zM12 13.031v-8.469h-8v8.469c0 0.125 0.031 0.25 0.063 0.375 0.031 0.094 0.094 0.188 0.125 0.219 0.063 0.063 0.094 0.094 0.094 0.094h7.438c0 0 0.031-0.031 0.094-0.094 0.031-0.031 0.094-0.125 0.125-0.219 0.031-0.125 0.063-0.25 0.063-0.375zM6 3.438h4l-0.438-1.063c-0.031-0.031-0.094-0.063-0.156-0.094h-2.813c-0.063 0.031-0.125 0.063-0.156 0.094zM14.281 3.719v0.563c0 0.094-0.031 0.156-0.063 0.219-0.063 0.031-0.125 0.063-0.219 0.063h-0.844v8.469c0 0.5-0.156 0.938-0.438 1.281-0.281 0.375-0.625 0.531-1 0.531h-7.438c-0.375 0-0.719-0.156-1-0.5s-0.438-0.781-0.438-1.281v-8.5h-0.844c-0.094 0-0.156-0.031-0.219-0.063-0.031-0.063-0.063-0.125-0.063-0.219v-0.563c0-0.094 0.031-0.156 0.063-0.219 0.063-0.031 0.125-0.063 0.219-0.063h2.75l0.625-1.5c0.094-0.219 0.25-0.406 0.5-0.563 0.219-0.156 0.469-0.219 0.688-0.219h2.875c0.219 0 0.469 0.063 0.688 0.219 0.25 0.156 0.406 0.344 0.5 0.563l0.625 1.5h2.75c0.094 0 0.156 0.031 0.219 0.063 0.031 0.063 0.063 0.125 0.063 0.219z"></path></svg>
`
}

function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj))
}
